import request from './request'
import {uuid} from 'vue-uuid'
import Utils from '../utils'
const FX = {
  autoFX(data){
    data.idempotency = uuid.v1()
    return request({
      url: "/v1/fx/auto",
      method: "post",
      data: data
    });
  },
  manualFX(data){
    data.idempotency = uuid.v1()
    return request({
      url: "/v1/fx/manual",
      method: "post",
      data: data
    });
  },
  quote(data){
    data.idempotency = uuid.v1()
    return request({
      url: "/v1/fx/quote",
      method: "post",
      data: data
    });
  },
  instant(data){
    data.idempotency = uuid.v1()
    return request({
      url: "/v1/fx/instant",
      method: "post",
      data: data
    });
  },
  instantInfoV2(query){
    return request({
      url: `/v2/payment/instant${Utils.obj2query(query)}`,
      method: "get"
    });
  },
  instantV2(data){
    data.idempotency = uuid.v1()
    return request({
      url: "/v2/payment/instant",
      method: "post",
      data: data
    });
  },
  serviceActive(){
    return request({
      url: "/v1/fx/serviceisactive",
      method: "get"
    });
  }
}

export default FX