<template>

    <div>
      <router-link 
        v-if="accessRole.payment && $route.path != '/payment'"
        :to="{ path: '/payment' }" 
        class="btn btn-danger btn-block"
        >Make a Payment</router-link
      >
      <router-link
        v-if="accessRole.statement && $route.path != '/statement'"
        :to="{ path: '/statement' }"
        class="btn btn-primary btn-block"
        >Statement</router-link
      >
      <router-link 
        v-if="accessRole.payee && $route.path != '/payee'"
        :to="{ path: '/payee' }" 
        class="btn btn-warning btn-block"
        >Manage My Payee</router-link
      >
      <router-link
        v-if="accessRole.autofx && $route.path != '/auto-fx'"
        :to="{ path: '/auto-fx' }"
        class="btn btn-secondary btn-block"
        >Auto FX</router-link
      >
      <router-link
        v-if="isBusiness && accessRole.manualfx && $route.path != '/request-fx'"
        :to="{ path: '/request-fx' }"
        class="btn btn-info btn-block"
        >Request FX</router-link
      >
      <router-link
        v-if="accessRole.card && $route.path != '/card/issue'"
        :to="{ path: '/card/issue' }"
        class="btn btn-success btn-block"
        >Request Debit Card</router-link
      >
      <router-link 
        v-if="accessRole.viewprofile && $route.path != '/profile'"
        :to="{ path: '/profile' }" class="btn btn-orange btn-block"
        >Edit profile</router-link
      >

    </div>

</template>

<script>
export default {
  computed:{
    isBusiness() {
      return this.$store.getters.business;
    },
    accessRole(){
      return this.$store.getters.accessrole
    },
  }
};
</script>

<style>
</style>